import React, { Component } from 'react';
import './Poker.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import isEmpty from "lodash/isEmpty";
import Image from 'react-bootstrap/Image';
import 'bootstrap/dist/css/bootstrap.min.css';

class Poker extends Component {

  constructor(props) {
    super(props);
    this.state = {
        hand: [],
        enemyhand: [],
        winner: "",
    };
};

componentDidMount() {
    this.shuffleAndDetermineWinner();
}

shuffleAndDetermineWinner = () => {
    // Define the card suits and ranks
    const suits = ["Hearts", "Diamonds", "Clubs", "Spades"];
    const ranks = [
      "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"
    ];

    // Create a standard deck of 52 cards
    const deck = [];
    for (const suit of suits) {
      for (const rank of ranks) {
        deck.push({ suit, rank });
      }
    }

    // Function to shuffle the deck using Fisher-Yates algorithm
    const shuffleDeck = (deck) => {
      for (let i = deck.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [deck[i], deck[j]] = [deck[j], deck[i]];
      }
    };

    // Function to deal 5 cards from the shuffled deck
    const dealCards = (deck, numCards) => {
      const hand = [];
      for (let i = 0; i < numCards; i++) {
        hand.push(deck.pop());
      }
      return hand;
    };

    // Shuffle the deck
    shuffleDeck(deck);

    // Deal 5 cards to the player
    const hand = dealCards(deck, 5);

    // Deal 5 cards to the enemy
    const enemyhand = dealCards(deck, 5);

    // Determine the winner
    const playerHandRank = this.evaluateHand(hand);
    const enemyHandRank = this.evaluateHand(enemyhand);
    let winner = "";
    if (playerHandRank > enemyHandRank) {
      winner = "Player wins!";
    } else if (playerHandRank < enemyHandRank) {
      winner = "Opponet wins!";
    } else {
      winner = "It's a tie!";
    }

    // Update the component state
    this.setState({
      hand,
      enemyhand,
      winner,
    });
};

evaluateHand = (hand) => {
  // Convert the hand to an array of ranks and suits
  const ranks = hand.map((card) => card.rank);
  const suits = hand.map((card) => card.suit);

  // Check for special hands
  if (this.isRoyalFlush(ranks, suits)) {
    return 10; // Royal Flush
  }
  // Check for special hands
  if (this.isStraightFlush(ranks, suits)) {
    return 9; // Straight Flush
  }

  // Implement checks for other special hands (e.g., Four of a Kind, Full House, etc.)

  // If no special hands, check for high card rank
  return this.getHighCardRank(ranks);
};

isRoyalFlush = (ranks, suits) => {
  const royalRanks = ["10", "J", "Q", "K", "A"];

  // Check if all ranks are royal ranks
  if (ranks.every((rank) => royalRanks.includes(rank))) {
    // Check if all suits are the same
    if (new Set(suits).size === 1) {
      // Check if the ranks are in sequence
      const sortedRanks = ranks.sort((a, b) => this.getRankValue(a) - this.getRankValue(b));
      const expectedRanks = royalRanks.slice(0, ranks.length);

      if (JSON.stringify(sortedRanks) === JSON.stringify(expectedRanks)) {
        return true;
      }
    }
  }
  // If no special hands, check for high card rank
  return false;
};

isStraightFlush = (ranks, suits) => {
  // Check if all suits are the same
  if (new Set(suits).size === 1) {
    // Check if the ranks are in sequence
    const sortedRanks = ranks.sort((a, b) => this.getRankValue(a) - this.getRankValue(b));
    const minRank = this.getRankValue(sortedRanks[0]);
    const maxRank = this.getRankValue(sortedRanks[sortedRanks.length - 1]);
    // Check for Ace-low straight
    if (maxRank - minRank === 4) {
      return true;
    }
  }
  // If no special hands, check for high card rank
  return false;
};

getHighCardRank = (ranks) => {
  // Return the highest rank value in the hand
  const rankValues = ranks.map((rank) => this.getRankValue(rank));
  return Math.max(...rankValues);
};

getRankValue = (rank) => {
  // Assign a numeric value to each rank for comparison
  const rankValues = {
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9,
    "10": 10,
    J: 11,
    Q: 12,
    K: 13,
    A: 14,
  };
  // Return the rank value
  return rankValues[rank];
};

    render() {

      const { hand, enemyhand, winner } = this.state;

        return (
            <div className="Poker">
                <h1>Your Cards</h1>
                <Button onClick={() => this.shuffleAndDetermineWinner()}>Shuffle Deck</Button>
                <Row xs={1} md={3} className="g-4">
                    {hand.map((y, index) => (
                            <Col key={index} style={{ flexBasis: "20%" }}>
                                <div className="Player-Card">
                                  <div className={`${y.suit.charAt(0)}${y.rank}`}>
                                    <Card className="Card">
                                    </Card>
                                  </div>
                                </div>
                            </Col>
                    ))}
                </Row>
                <h1>Opponent Cards</h1>
                <Row xs={1} md={3} className="g-4">
                    {enemyhand.map((e, index) => (
                            <Col key={index} style={{ flexBasis: "20%" }}>
                                <div className="Opponent-Card">
                                  <div className={`${e.suit.charAt(0)}${e.rank}`}>
                                    <Card className="Card">
                                    </Card>
                                  </div>
                                </div>
                            </Col>
                    ))}
                </Row>
                <h1>{this.state.winner}</h1>
            </div>
            );
	};

}
// export the Poker component
export default Poker;