// Purpose: This file is the main file for the application. It is the parent component that contains the Poker component.
import logo from './logo.svg';
// import the Poker component
import './App.css';
import Poker from './components/Poker/Poker';
// place the Poker component in the App component
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <main>
        <Poker />
        <div className='Clear'></div>
      </main>
    </div>
  );
}
// export the App component
export default App;
